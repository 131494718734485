@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alatsi&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
	font-family: 'Wonderworld';
	src: url('../../public/wonderworld.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
} */

@font-face {
    font-family: "Neuebit";
    src: url("./assets/fonts/neuebit.woff") format("woff"),
    url("./assets/fonts/neuebit.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "retro";
    src: url("./assets/fonts/retro.woff") format("opentype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.serif {
    font-family: "Source Serif 4", serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
}

.alatsi-regular {
    font-family: "Alatsi", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.inter {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

body {
    scroll-behavior: smooth;
    background: #202f53;
    font-family: "Alatsi", sans-serif;
    color: white;
}

li {
    margin-left: 10px;
    list-style-type: circle;
}

a:link {
    text-decoration: underline;
}

img,
canvas {
    /* We have a lot of pixel art, so let's default to this instead of some modern blurred renderer */
    image-rendering: pixelated;
}

.clear {
    image-rendering: pixelated;
}

.gradient-text {
    background: linear-gradient(270deg, #ff4186, #5be2fa);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.neuebit {
    font-family: "Neuebit", sans-serif;
}

.retro {
    font-family: "retro", sans-serif;
}

a:hover {
    color: #5be2fa;
}

@keyframes bobbing {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px); /* Adjust the value as needed */
    }
}

.bobbing {
    animation: bobbing 2s infinite; /* Adjust the duration as needed */
}

.hue-rot {
    filter: hue-rotate(-65deg) brightness(1.8);
}

::selection {
    background: #5be2fa;
}

#background {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

#background::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(ellipse, mediumturquoise, darkslateblue);
    filter: url(#grainy);
}

.grainy-bg {
    background: radial-gradient(ellipse, black, transparent);
    opacity: 0.2;
    filter: url(#grainy);
}

#background::after {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
